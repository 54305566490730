<template>
  <main class="navbar-offset-tablet salon-flow ">
    <PageNavigator :page-title="$t('booking_mask.breadcrumbs-list-label')" :has-back="false"> </PageNavigator>

    <div class="pt-6 full-page-height d-flex flex-column">
      <div class="py-1-5 px-1-5 text-center">
        <span class="text-black poppins-font font-weight-medium">
          {{ $t('booking_mask.content.page_desctiptions') }}
        </span>
      </div>
      <div class="row">
        <div class="col-md-6 col-12 pr-md-0 border-right border-top">
          <div class="block-header p-1-5 mb-1-5 border-bottom">
            <span class="text-black poppins-font font-weight-medium">
              {{ $t('booking_mask.content.widget_block_title') }}
            </span>
          </div>

          <div class="d-flex justify-content-center mb-1-5 px-1-5">
            <div v-if="moderationCompleted">
              <button v-if="!frame" class="btn btn-primary px-md-4" @click="generateFrame()">
                {{ $t('booking_mask.content.generate_button') }}
              </button>

              <div v-else class="text-center">
                <p class="text-left text-black">
                  {{ $t('booking_mask.content.instruction_text') }}
                </p>
                <p class="p-1-5 rounded bg-primary text-white font-weight-medium mb-1-5">{{ frame }}</p>
                <button class="btn btn-checkbox mb-1-5" @click="copyScriptCode()">
                  {{ $t('booking_mask.content.copy_button') }}
                </button>
                <input id="copy-selector" type="hidden" :value="frame" />

                <p class="text-left text-black">
                  {{ $t('booking_mask.content.widget_instruction') }}
                </p>
              </div>
            </div>
            <div v-else>
              {{ $t('booking_mask.content.requirements_text') }}
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12 pl-md-0 border-top">
          <div class="block-header p-1-5 mb-1-5 border-bottom">
            <span class="text-black poppins-font font-weight-medium">
              {{ $t('booking_mask.content.example_block_title') }}
            </span>
          </div>
          <div class="d-flex justify-content-center mb-1-5 px-1-5">
            <picture>
              <source
                srcset="@/assets/images/examples/website_widget.jpg, @/assets/images/examples/website_widget@x2.jpg 2x"
              />
              <img src="@/assets/images/examples/website_widget.jpg" class="w-100" alt="" />
            </picture>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import PageNavigator from '@/components/PageNavigator'
import { mapGetters, mapActions } from 'vuex'
import config from 'config'

export default {
  name: 'BookingMask',
  components: {
    PageNavigator
  },
  data() {
    return {
      frame: ''
    }
  },
  computed: {
    ...mapGetters({
      generalInfo: 'salons/getGeneral',
      accountInfo: 'user/getAccountInfo'
    }),
    moderationCompleted() {
      return this.generalInfo.status === 'Active'
    },
    salonId() {
      return this.accountInfo.salon_id
    }
  },
  mounted() {},
  methods: {
    ...mapActions({
      setSuccess: 'userMessages/setSuccess',
      setError: 'userMessages/setError'
    }),
    randomString(length) {
      let result = ''
      let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      let charactersLength = characters.length

      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }

      return result
    },
    generateFrame() {
      this.frame = `<iframe id="frame" allowpaymentrequest="true" src="${
        config.bookingMaskAppUrl
      }?key=${this.randomString(7)}_${this.salonId}" width="100%" height="600px" style="border: none;"></iframe>`
    },
    copyScriptCode() {
      let copySelector = document.querySelector('#copy-selector')

      copySelector.setAttribute('type', 'text')
      copySelector.select()

      try {
        let successful = document.execCommand('copy')

        this.setSuccess({
          type: 'Success!',
          messageI18Key: 'common.alerts.actions.success'
        })
      } catch (err) {
        this.setError({
          type: 'Error!',
          messageI18Key: 'common.alerts.actions.error'
        })
      }

      copySelector.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    }
  }
}
</script>
<style scoped></style>
